import React, { FunctionComponent } from 'react'
import { Card, Grid, CardContent, makeStyles } from '@material-ui/core'
import { DataColumn } from '../DataColumn'

const useStyles = makeStyles(() => ({
  gridItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  }
}))


interface CustomerDataProps {
  brick: any
}

export const CustomerData: FunctionComponent<CustomerDataProps> = ({ brick }) => {
  const classes = useStyles()

  const {
    firstName,
    lastName,
    mobilePhone,
    birthday,
    deliveryStartDate,
    customerNumber,
  } = brick.content.data
  return (
    <Card elevation={2}>
      <CardContent>
        <Grid container className={classes.row}>
          <Grid item xs={12} md={4}>
            <DataColumn title="Name" bodyTexts={[firstName, lastName]} />
            <DataColumn title="Mobilnummer" bodyTexts={[mobilePhone]} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DataColumn title="Geburtstag" bodyTexts={[birthday]} />
            <DataColumn title="Lieferbeginn" bodyTexts={[deliveryStartDate]} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DataColumn title="Kundennummer" bodyTexts={[customerNumber]} />
          </Grid>
        </Grid>
      </CardContent>
    </Card >
  )
}
