import React, { useEffect, FunctionComponent } from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { anonymLogin, passwordessTokenLogin } from "./redux/login";
import { setChannel } from "./redux/channel";
import { setBaseUrl } from "./redux/config";

import { ChatUi } from "./container/ChatUi/ChatUi";
import { CssBaseline} from "@material-ui/core";
import { useLocation } from "react-router-dom";

interface Props {
  channelId: string,
  baseUrl: string,
}


// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);

}




const App: FunctionComponent<Props> = ({ channelId, baseUrl }) => {
  const { dispatch } = store;
  const query = useQuery();

  const passwordLessToken = query.get('plt')
  console.log('passwordLessToken', passwordLessToken)

  const startEventName = query.get('se')
  console.log('startEventName', startEventName)


  useEffect(() => {
    if(!!baseUrl && !!channelId){
      dispatch(setBaseUrl(baseUrl));
      dispatch(setChannel(channelId));

      if(passwordLessToken) {
        dispatch(passwordessTokenLogin(channelId, passwordLessToken));
      } else {
        dispatch(anonymLogin(channelId));
      }

    }
  }, [channelId, baseUrl, passwordLessToken, dispatch]);

  return (
    <Provider store={store}>
      <CssBaseline />
      <ChatUi startEventName={startEventName!}/>


    </Provider>
  );
};

export default App;
