import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ChatUIApp from './ChatUIApp';
import * as serviceWorker from './serviceWorker';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";


const baseUrl = process.env.REACT_APP_AI_EVENT_API_BASE_URL ||  "http://localhost:3017"
const channelId = process.env.REACT_APP_CHANNEL_ID ||  "61974a2351cc4302de03a13f"

// const baseUrl = process.env.REACT_APP_AI_EVENT_API_BASE_URL ||  "https://agile-demo-ai-event-api.agilegravity.com"
// const channelId = process.env.REACT_APP_CHANNEL_ID ||  "60a3abd45a8c4e000933d88b"


ReactDOM.render(
  <React.StrictMode>
  <Router>
    <Switch>
      <Route path="/app">
        <App
          baseUrl={baseUrl}
          channelId={channelId}
        />
      </Route>
      <Route path="/chat">
        <ChatUIApp
          baseUrl={baseUrl}
          channelId={channelId}
        />
      </Route>
      <Route exact path="/">
        <Redirect to="/chat"/>
      </Route>
    </Switch>

  </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
