import React, {FunctionComponent, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';


import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {Container} from "@material-ui/core";
import { Skeleton } from '@material-ui/lab';
// import { Skeleton } from '@material-ui/lab';
import {RootState} from '../../redux/rootReducer';


import {UniversalBrick} from "../../components";
import { ChatMessageTextField} from "../../components/Bricks";

import { sentChatStartEvent, getBricksFromTopic } from '../../redux/bricks';

export interface ChathUiProps {
  // scene: any
  startEventName?: string ,
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      height: '100%',
    },
    html: {
      height: '100%',
    },
    '#root': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
  },
  root: {
    // position: 'relative',
    // height: '100vh',
    maxWidth: 640,
    minWidth: 320,
    marginRight:"auto",
    marginLeft: "auto",
    height: '100%',
    display: 'flex',
    flex: '1 1 0%',
    minHeight: 0
  },
  chatWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  chatSectionRoot: {
   //  width: '100%',
   // // height: 'calc(100vh - 72px)',
    overflowY:'scroll',
    flex: '1 1 0',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'flex-end'
  },
  chatSectionMessages: {
    // alignContent: 'flex-end',
    height: 'auto',

    // minHeight: '100%'
},
  messageTextfield: {
    // marginTop: theme.spacing(2),
    // width: '100%',

    flex: '0 1 auto',
    display: 'flex',
    alignContent: 'flex-end',
  },
  botMessage: {
    display: "flex",
    justifyContent: "flex-start",

    marginRight: 40,
  },
  ownerMessage: {
    display: "flex",
    justifyContent: "flex-end",

    marginLeft: 40,
  }
}));




export const ChatUi = ({startEventName}: ChathUiProps) => {
  const dispatch = useDispatch();
  const {bricks} = useSelector(({bricks}: RootState) => bricks);
  const session = useSelector(({session}: RootState) => session);

  const isReady = !!session?.user;

  const scrollRef = React.useRef<HTMLDivElement>(null);

  const scroll = React.useCallback((): void => {
    if (scrollRef.current) {
      console.log('scroll', scrollRef.current.scrollTop, scrollRef.current.scrollHeight)
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      scrollRef.current.scrollIntoView(true);
    }
  }, [scrollRef]);

  useEffect(() => {
    scroll()
  }, [bricks, scroll])

  useEffect(() => {
    if(isReady){
      dispatch(sentChatStartEvent(startEventName))
      dispatch(getBricksFromTopic())
    }
  },[isReady])

  const classes = useStyles();
  return (

    <Container maxWidth="sm" className={classes.root}>
      <div className={classes.chatWrapper}>
        <Paper
          className={classes.chatSectionRoot}
          ref={scrollRef}
        >
          <Grid className={classes.chatSectionMessages}
            container
                spacing={1}
                direction="row-reverse"
                alignContent="flex-end"
                // alignItems="flex-end"
                // justifyContent="flex-end"

          >
            {bricks.map((brick) => {
              const messageClassName = brick.creator === 'human' ? classes.ownerMessage : classes.botMessage;
              return (
                <Grid key={brick._id} item xs={12} >
                  <Box className={messageClassName}>
                    <UniversalBrick brick={brick} key={brick._id}/>
                  </Box>
                </Grid>
              );
              })}
          </Grid>
        </Paper>
        <Box
          className={classes.messageTextfield}
        >
          {isReady ? <ChatMessageTextField /> :

            <Skeleton>
              <ChatMessageTextField />
            </Skeleton>
          }

        </Box>
      </div>
    </Container>
  );
};
