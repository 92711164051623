import { createSlice } from "@reduxjs/toolkit";
import { getBricks } from "./brickApi";
import { AppThunk } from "./store";
import { Brick, SchemaRef } from "../models";
import {
  Client,
  Event,
  Payload,
  SuggestedOptions,
  Content,
  EventScope,
} from "@agilegravity/agile-ai-event-client-ts";

interface BricksState {
  bricks: Array<Brick<SchemaRef>>;
  error?: string;
  dateFrom?: Date;
  dateTo?: Date;
  currentText?: string;
}

const initialState: BricksState = { bricks: [], error: undefined };

const brickSlice = createSlice({
  name: "bricks",
  initialState: initialState,
  reducers: {
    getBricksSuccess: (state, action) => {
      if(action.payload) {
        return (
          {
      ...state,
      bricks: [
        // ...state.bricks,
        ...action.payload,
      ],
          })
      }
      return state
    },
    getBricksAppendSuccess: (state, action) => {
      if(action.payload) {
      const bricks =   [
        ...state.bricks,
        ...action.payload,
      ];

      bricks.sort((a, b) => (a.createdAt?.getTime() - b.createdAt?.getTime()))

      return {
      ...state,
        bricks,
      }
      } else {
        return state
      }
    },
    getBricksFailed: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    setDateFrom: (state, action) => ({
      ...state,
      dateFrom: action.payload,
    }),
    setDateTo: (state, action) => ({
      ...state,
      dateTo: action.payload,
    }),
    setCurrentText: (state, action) => ({
      ...state,
      currentText: action.payload,
    }),
  },
});
export const {
  getBricksSuccess,
  getBricksAppendSuccess,
  getBricksFailed,
  setDateFrom,
  setDateTo,
  setCurrentText,
} = brickSlice.actions;

export default brickSlice.reducer;

export const fetchBricks = (): AppThunk => async (dispatch) => {
  try {
    const bricks = await getBricks();
    dispatch(getBricksSuccess(bricks));
  } catch (err) {
    dispatch(getBricksFailed(err.toString()));
  }
};


export const getBricksFromTopic = (): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    console.log('getBricksFromTopic', state)
    const client = new Client(state.config.baseUrl);

    const {
      session: {
        user: { topicId },
      },
      channel: { channelId },
      login: {
        user: { access_token, userID },
      },
    } = state;

    // @ts-ignore
    client.instance.defaults.headers.common.Authorization = access_token;
    // @ts-ignore
    const bricks = await client.getBricks(
      channelId,
      userID,
      // @ts-ignore
      topicId
    );
    console.log("bricks form event api", bricks);


    dispatch(getBricksAppendSuccess(bricks));
  } catch (err) {
    dispatch(getBricksFailed(err.toString()));
  }
};



export const processEvent = (
  events: Event[],
  updateStore = true,
  append = false
): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    console.log("state", state);
    // @ts-ignore
    const {
      session: {
        user: { topicId },
      },
      channel: { channelId },
      login: {
        user: { access_token, userID },
      },
    } = state;
    console.log('login getState', getState())
    const client = new Client(state.config.baseUrl);
    // @ts-ignore
    client.instance.defaults.headers.common.Authorization = access_token;
    // @ts-ignore
    const bricks = await client.processEvent(
      channelId,
      userID,
      // @ts-ignore
      topicId,
      events
    );
    console.log("bricks form event api", bricks);
    if(append && bricks) {
      dispatch(getBricksAppendSuccess(bricks));
    } else {
      if (updateStore) {
        dispatch(getBricksSuccess(bricks));
      }
    }

  } catch (err) {
    console.log(err);
    if (updateStore) {
      dispatch(getBricksFailed(err.toString()));
    }
  }
};

const getTextEvent = (text: string): Event => {
  // @ts-ignore
  const event = new Event({
    name: "fulltextsearchExtended",
    tpKey: "yello-DE",
    // @ts-ignore
    scope: "event",
    payload: new Payload({
      eventCategory: "UI",
      eventAction: "input",
      source: "Szenario_FAQ.SearchBar.SearchStage",
    }),
    text,
  });
  return event;
};

export const sentMicrocontextUpdate = (data): AppThunk => async (
  dispatch,
  getState
) => {
  const events: Event[] = [];
  // @ts-ignore
  const event = new Event({
    name: "microcontex-update",
    payload: new Payload({
      eventCategory: "UI",
      eventAction: "ContextTrigger",
      source: "CTX",
    }),
    content: new Content({
      schemaRef: "MicroContext",
      data,
    }),
    scope: EventScope.Session,
    tpKey: "yello-DE",
    suggestedOptions: [],
  });
  events.push(event);

  const state = getState();
  const {
    bricks: { currentText },
  } = state;
  if (currentText) {
    const textEvent = getTextEvent(currentText);
    events.push(textEvent);
  }

  await dispatch(processEvent(events));
};

export const sentUniversalEvent = (myevent): AppThunk => async (
  dispatch
) => {
  const events: Event[] = [];
  // @ts-ignore
  const event = Event.fromJS(myevent);
  events.push(event);

  // const state = getState();
  // const { bricks: { currentText } } = state;
  // if(currentText) {
  //   const textEvent = getTextEvent(currentText)
  //   events.push(textEvent)
  // }

  await dispatch(processEvent(events));
};

export const sentTextEvent = (text: string): AppThunk => (dispatch) => {
  // @ts-ignore
  const event = getTextEvent(text);
  dispatch(setCurrentText(text));
  dispatch(processEvent([event]));
};


export const sentChatStartEvent = (startEventName?: string): AppThunk => (dispatch) => {

  // @ts-ignore
  const event = new Event({
    name:  startEventName || "chatAutoMirrorResponder",
    tpKey: "de",
    // @ts-ignore
    scope: "event",
    payload: new Payload({
      eventCategory: "UI",
      eventAction: startEventName || "chatAutoMirrorResponder",
      source: "Szenario_FAQ.SearchBar.SearchStage",
    }),

  });
  console.log('sentChatStartEvent', event)
  dispatch(processEvent([event], false, true));
};

export const sentChatMessageEvent = (text: string): AppThunk => (dispatch) => {
  // @ts-ignore
  const eventText = new Event({
    // name: "chatAutoMirrorResponder",
    tpKey: "de",
    // @ts-ignore
    scope: "event",
    payload: new Payload({
      eventCategory: "UI",
      eventAction: "chatAutoMirrorResponder",
      source: "Szenario_FAQ.SearchBar.SearchStage",
    }),
    text,
  });// @ts-ignore
  const eventMirror = new Event({
    name: "mirrorBrick",
    tpKey: "de",
    // @ts-ignore
    scope: "event",
    payload: new Payload({
      eventCategory: "UI",
      eventAction: "chatAutoMirrorResponder",
      source: "Szenario_FAQ.SearchBar.SearchStage",
    }),
    text,
  });
  dispatch(processEvent([eventMirror,eventText], false, true));
};

// @ts-ignore
export const sentSuggesteOptionEvent = (
  // @ts-ignore
  brick: Brick,
  suggestedOption: SuggestedOptions
): AppThunk => (dispatch) => {
  // @ts-ignore
  const event = new Event({
    tpKey: "de",
    // @ts-ignore
    name: "relatedContentSearch",
    // @ts-ignore
    scope: "event",
    // options: {
    //   createBrick: true,
    // },
    payload: new Payload({
      eventCategory: "BAI:SEARCH",
      eventAction: "click",
      source: brick._id, //brick._id aus der die suggestedOption geklickt wurde
    }),
    suggestedOptions: [
      new SuggestedOptions({
        _id: suggestedOption._id,
      }),
    ],
  });
  const eventMirrorTextToUser = new Event({
    tpKey: "de",
    // @ts-ignore
    name: "mirrorBrick",
    // @ts-ignore
    scope: "event",
    // options: {
    //   createBrick: true,
    // },
    payload: new Payload({
      eventCategory: "BAI:SEARCH",
      eventAction: "click",
      source: brick._id, //brick._id aus der die suggestedOption geklickt wurde
    }),
    suggestedOptions: [
      new SuggestedOptions({
        _id: suggestedOption._id,
      }),
    ],
  });
  dispatch(processEvent([eventMirrorTextToUser, event ], true, true));
};
