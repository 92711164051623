import React, { FunctionComponent } from 'react'
import { Typography } from '@material-ui/core'

interface TitleProps {
  title: string
}


export const Title: FunctionComponent<TitleProps> = ({ title }) => {
  return (
    <Typography variant={"h6"}>
      {title}
    </Typography>
  )
}