import { createSlice } from '@reduxjs/toolkit'

interface State {
  heights: { [id: string]: number },
  expandedItemId?: string
}

const initialState: State = { heights: {}, expandedItemId: undefined }

const slice = createSlice({
  name: 'masonry',
  initialState: initialState,
  reducers: {
    setHeight: (state, action) => ({
      ...state,
      heights: {
        ...state.heights,
        [action.payload.id]: action.payload.height,
      }
    }),
    expandItem: (state, action) => ({
      ...state,
      expandedItemId: action.payload
    }),
    collapseItem: (state) => ({
      ...state,
      expandedItemId: undefined
    }),
    toggleExpand: (state, action) => ({
      ...state,
      expandedItemId: state.expandedItemId === action.payload ? undefined : action.payload
    })
  }
})
export const { setHeight, expandItem, collapseItem, toggleExpand } = slice.actions

export default slice.reducer
