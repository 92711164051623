import { createSlice } from '@reduxjs/toolkit'
import { AppThunk } from './store'
import { IToken, Client, Login } from "@agilegravity/agile-ai-event-client-ts"
import { getSessionForUserId } from "./session";

interface LoginState {
  user: IToken
  error?: string
}

// @ts-ignore
const initialState: LoginState = { error: undefined }

const loginSlice = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    loginSuccess: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    loginFailed: (state, action) => ({
      ...state,
      error: action.payload,
    }),
  }
})
export const { loginSuccess, loginFailed } = loginSlice.actions

export default loginSlice.reducer

export const login = (channelId: string, login: Login): AppThunk => async (dispatch, getState) => {
  try {
    console.log('login getState', getState())
    const state = getState()
    const channel = new Client(state.config.baseUrl)
    const user = await channel.login(channelId, undefined, login)
    dispatch(loginSuccess(user.toJSON()))

    dispatch(getSessionForUserId(channelId, user.userID));


  } catch (err) {
    dispatch(loginFailed(err.toString()))
  }
}

export const anonymLogin = (channelId: string): AppThunk => dispatch => {
  const loginBody = new Login({
    isAnonym: true
  })
  dispatch(login(channelId, loginBody))
}

export const passwordessTokenLogin = (channelId: string, passwordLessToken: string): AppThunk => dispatch => {
  const loginBody = new Login({
    isAnonym: false,
    passwordLessToken,

  })
  dispatch(login(channelId, loginBody))
}

