import React, { FunctionComponent } from 'react'
import JSONTree from 'react-json-tree'
import { Brick, SchemaRef } from '../../models'
import { DEFAULT_HEIGHT } from '../Masonry/Masonry'
import { Card, makeStyles } from '@material-ui/core'
import { Title } from './Title'

const useStyles = makeStyles(() => ({
  root: {
    height: DEFAULT_HEIGHT,
    overflow: 'auto',
    padding: 10
  }
}))


const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#272822',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633'
};

interface UnknownProps {
  brick: Brick<SchemaRef>
}

export const Unknown: FunctionComponent<UnknownProps> = ({ brick }) => {
  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <Title title={`Unbekannter schemaRef: ${brick.content.schemaRef}`} />
      <JSONTree data={brick} theme={theme} invertTheme={true} />
    </Card>
  )
}
