import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { sentChatMessageEvent } from '../../redux/bricks';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import {makeStyles} from "@material-ui/core";


interface ChatMessageTextFieldProps {
  brick?: any,
}
const useStyles = makeStyles(() => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',

  },
  sendButton: {
    // minWidth: 40,
  },
  textField: {
    flex: 1
  }
}))


export const ChatMessageTextField: FunctionComponent<ChatMessageTextFieldProps> = ({ brick }) => {
  const classes = useStyles()
  const dispatch = useDispatch();

  const [msg, setMsg] = useState('')

  const sendMessage = () => {
    dispatch(sentChatMessageEvent(msg));
    setMsg('')
  }


  return (

      <Paper className={classes.root}>

          <TextField
            className={classes.textField}
            multiline
            rowsMax={3}
            value={msg}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                sendMessage()
                event.preventDefault();
              }
            }}
            onChange={(event) => {
              setMsg( event.target.value)
            }}
            id="outlined-basic-email"
            label="send message"
            fullWidth />
            <Fab
              className={classes.sendButton}
            color="primary"
            aria-label="add"
            onClick={sendMessage}
          >
            <SendIcon />
          </Fab>

      </Paper>
  );
};
