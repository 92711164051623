import React, { useEffect, FunctionComponent } from "react";
import { Provider } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";import { store } from "./redux/store";

import { BrickWall } from "./container/BrickWall/BrickWall";
import { SearchUi } from "./container/SearchUi/SearchUi";
import { MicroIntendList } from "./container/MicroIntendList/MicroIntendList";
import { setChannel } from "./redux/channel";
import { setBaseUrl } from "./redux/config";
import { anonymLogin } from "./redux/login";
import { CustomerDetailUI } from "./container/CustomerDetailUI/CustomerDetailUI";
import { BrickList } from "./container/BrickList/BrickList";
import { EventPlayground } from "./container/EventPlayground/EventPlayground";

import { SceneSearchUi } from "./container/Scene/SceneSearchUi";
import {ChatUi} from "./container/ChatUi/ChatUi";
import {IJSONSchema} from "@agilegravity/agile-ai-trainerserver-client-ts";



interface Props {
  channelId: string,
  baseUrl: string,
  startEventName? :string,
  eventPlaygroundJSONSchemas?: IJSONSchema[],
  eventPlaygroundTpKeys?: string[],
}



const App: FunctionComponent<Props> = ({
  channelId,
  baseUrl,
  startEventName,
  eventPlaygroundJSONSchemas,
  eventPlaygroundTpKeys,
}) => {

  const { dispatch } = store;
  useEffect(() => {
    if(!!baseUrl && !!channelId){
      dispatch(setBaseUrl(baseUrl));
      dispatch(setChannel(channelId));
      dispatch(anonymLogin(channelId));
    }
  }, [channelId, baseUrl, dispatch]);
  // const { user } = useSelector(
  //   (state: RootState) => state.login,
  // );
  // useEffect(() => {
  //   if (user && user.userID) {
  //     dispatch(getSessionForUserId(channel, user.userID));
  //   }
  // }, [channel, dispatch, user]);



  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Provider store={store}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="EventPlayground" />
          {/*<Tab label="BrickList" />*/}
          {/*<Tab label="BrickWall" />*/}
          {/*<Tab label="Search Ui" />*/}
          {/*<Tab label="Agent Assist" />*/}
          {/*<Tab label="Search Ui Scene" />*/}
          <Tab label="Chat UI" />
        </Tabs>
        {value === 0 &&
          <EventPlayground
            eventPlaygroundJSONSchemas={eventPlaygroundJSONSchemas}
            eventPlaygroundTpKeys={eventPlaygroundTpKeys}
          />
        }
        {/* {value === 1 && (
          <Grid container spacing={3} style={{ width: "100%" }}>
            <Grid item xs={8}>
              <BrickList />
            </Grid>
            <Grid item xs={4}>
              <MicroIntendList />
            </Grid>
          </Grid>
        )}

        {value === 2 && (
          <Grid container spacing={3} style={{ width: "100%" }}>
            <Grid item xs={8}>
              <SearchUi />
            </Grid>
            <Grid item xs={4}>
              <MicroIntendList />
            </Grid>
          </Grid>
        )}
        {value === 3 && <CustomerDetailUI />}
        {value === 4 && <SceneSearchUi />}
        {value === 5 && <BrickWall />} */}
        {value === 1 && <ChatUi startEventName={startEventName!} />
        }

    </Provider>
  );
};

export default App;
