import React, { CSSProperties, FunctionComponent } from 'react';
import { default as UiTable } from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { animated } from 'react-spring'
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { DataKey } from '../../models';

const AnimatedTableContainer = animated(TableContainer)

interface Props {
  style?: CSSProperties
  dataKeys: DataKey[]
  chartData: Array<any>
}

export const Table: FunctionComponent<Props> = ({ style, dataKeys, chartData }) => {
  return (
    // @ts-ignore
    <AnimatedTableContainer component={Paper} style={{ height: 200, ...style }}>
      <UiTable aria-label="simple table">
        <TableHead>
          <TableRow>
            {dataKeys.map(item => (
              <TableCell key={item.key}>{item.key}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {chartData.map((chartItem, i) => (
            <TableRow key={i}>
              {dataKeys.map((keyItem, i) => (
                <TableCell align="right" key={i}>{chartItem[keyItem.key]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </UiTable>
    </AnimatedTableContainer>
  );
}