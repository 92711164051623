import React, { FunctionComponent } from 'react'
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line as RechartsLine,
  ResponsiveContainer
} from 'recharts'
import { DataKey } from '../../models';

interface Props {
  data: Array<object>
  dataKeys: DataKey[]
}

const DEFAULT_COLOR = '#8884d8'

export const Line: FunctionComponent<Props> = ({ data, dataKeys }) => (
  <ResponsiveContainer width="100%" height={300}>
    <LineChart
      data={data}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis width={30} />
      <Tooltip />
      <Legend />
      {dataKeys.map(({ key, color }) => (
        <RechartsLine key={key} type="monotone" dataKey={key} stroke={color ? color : DEFAULT_COLOR} activeDot={{ r: 8 }} />
      ))}
    </LineChart>
  </ResponsiveContainer>
);