import React, { FunctionComponent } from 'react'
import DateFnsUtils from '@date-io/date-fns';
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface DatePickersProps {
  dateFrom?: Date
  dateTo?: Date
  onChangeFrom(date: MaterialUiPickersDate): void
  onChangeTo(date: MaterialUiPickersDate): void
}


export const DatePickers: FunctionComponent<DatePickersProps> = ({
  dateFrom,
  dateTo,
  onChangeFrom,
  onChangeTo
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        variant="inline"
        label="von"
        value={dateFrom}
        onChange={onChangeFrom}
        format="dd.MM.yyyy HH:mm"
      />
      <DateTimePicker
        variant="inline"
        label="bis"
        value={dateTo}
        onChange={onChangeTo}
        format="dd.MM.yyyy HH:mm"
      />
    </MuiPickersUtilsProvider>
  )
}
