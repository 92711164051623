import React, { FunctionComponent, useEffect } from 'react'
import { animated, useSpring } from 'react-spring'

import { BarVertical } from '../charts'
import { Paper } from '@material-ui/core'
import { Title } from './Title'
import { useDispatch } from 'react-redux'
import {  setHeight } from '../../redux/masonry'
const AnimatedPaper = animated(Paper)

interface Props {
  brick: any
}

const HEIGHT = 400

export const MicrointendList: FunctionComponent<Props> = ({ brick }) => {
  const dispatch = useDispatch()
  const brickData = brick.content?.data
  const { height } = useSpring({
    height: HEIGHT,
  })
  useEffect(() => {
    dispatch(setHeight({ id: brick._id, height: HEIGHT }))
  })
  return (
    <AnimatedPaper elevation={3} style={{ height, padding: 10 }}>
      <Title title={brickData.title} />
      <BarVertical
        data={brick.suggestedOptions.map(options => options.content.data)}
        dataKeys={[{ key: 'confidence' }]}
        labelListDataKey="namedState"
      />
    </AnimatedPaper>
  )
}
