import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import bricks from './bricks';
import masonry from './masonry';
import channel from './channel';
import login from './login';
import session from './session';
import config from './config';


const rootReducer = (history: any) => combineReducers({
  bricks,
  masonry,
  channel,
  login,
  session,
  config,
  router: connectRouter(history),
});

export type RootState = ReturnType<ReturnType<typeof rootReducer>>

export default rootReducer;
