import React, { FunctionComponent } from 'react'
import {
  PieChart,
  Pie as RechartsPie,
  Tooltip,
  ResponsiveContainer,
  Cell
} from 'recharts'
import { DataKey } from '../../models'

interface Props {
  data: any[]
  dataKeys: DataKey[]
}

export const Pie: FunctionComponent<Props> = ({ data, dataKeys }) => (
  <ResponsiveContainer width="100%" height={400}>
    <PieChart>
      {dataKeys.map(({ key }) => (
        <RechartsPie key={key} data={data} dataKey={key} nameKey="name" fill="#8884d8" label>
          {data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)}
        </RechartsPie>
      ))}
      <Tooltip />
    </PieChart>
  </ResponsiveContainer>
)