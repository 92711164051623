import { createSlice } from '@reduxjs/toolkit'

interface ChannelState {
  channelId: string,
  error?: string
}

// @ts-ignore
const initialState: ChannelState = {}

const channelSlice = createSlice({
  name: 'channel',
  initialState: initialState,
  reducers: {
    setChannel: (state, action) => ({
      ...state,
      channelId: action.payload,
    }),
  }
})
export const { setChannel } = channelSlice.actions

export default channelSlice.reducer
