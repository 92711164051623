import React, { FunctionComponent } from 'react'
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar as RechartsBar,
  ResponsiveContainer,
  LabelList
} from 'recharts'
import { DataKey } from '../../models';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  bar: {
    fill: '#c6c6c9'
  }
}))

interface BarProps {
  data: object[]
  stacked?: boolean
  dataKeys: Array<DataKey>
  labelListDataKey: string
}

const DEFAULT_COLOR = '#6a66b1'


export const BarVertical: FunctionComponent<BarProps> = ({
  data,
  stacked = false,
  dataKeys,
  labelListDataKey
}) => {
  const classes = useStyles()
  return (
    <ResponsiveContainer width="100%" height={310}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" fontFamily='Roboto", "Helvetica", "Arial", sans-serif' />
        <XAxis width={30} type="number" />
        <YAxis type="category" tick={false} width={1} />
        {/*<Tooltip cursor={{ fill: '#f2f2f2' }} />*/}
        <Legend />
        {dataKeys.map(({ key, color }) => (
          <RechartsBar
            layout="vertical"
            key={key}
            dataKey={key}
            fill={color ? color : DEFAULT_COLOR} stackId={stacked ? 'a' : undefined}
          >
            <LabelList key={key} dataKey={labelListDataKey} position="insideLeft" className={classes.bar} />
          </RechartsBar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
