import React, { FunctionComponent } from 'react';
import {
  List, makeStyles, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Divider, Tooltip,
} from '@material-ui/core';

import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { ISuggestedOptions } from '@agilegravity/agile-ai-event-client-ts';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

interface SearchResultProps {
  brick: any,
  suggestOptionClick(suggestedOption: ISuggestedOptions): void
}


export const SearchResult: FunctionComponent<SearchResultProps> = ({ brick, suggestOptionClick }) => {
  const classes = useStyles();


  return brick && (
    <List className={classes.root}>
      {brick.suggestedOptions.map((option: ISuggestedOptions) => (

        <React.Fragment key={option._id}>
          <Tooltip title={JSON.stringify(
            {
              // @ts-ignore
              actors: option.content?.data.actors,
              // @ts-ignore
              score: option.content?.data.score,
            }, null, 2)
          } >
            <ListItem
              key={option._id}
              alignItems="flex-start"
              button
              onClick={() => suggestOptionClick(option)}
            >
              <ListItemText
                primary={option.content?.data.title}
                secondary={option.content?.data.parentTitle}
                title="test"
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => suggestOptionClick(option)}>
                  <KeyboardArrowRight />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </Tooltip>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};
