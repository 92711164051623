import { createSlice } from '@reduxjs/toolkit';
import { ISessionData, Client, SessionBody } from '@agilegravity/agile-ai-event-client-ts';
import { AppThunk } from './store';

interface SessionState {
  user: ISessionData,
  error?: string
}
// @ts-ignore
const initialState: SessionState = { error: undefined };

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    getSessionSuccess: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    getSessionFailed: (state, action) => ({
      ...state,
      error: action.payload,
    }),
  },
});
export const { getSessionSuccess, getSessionFailed } = sessionSlice.actions;

export default sessionSlice.reducer;

export const getSessionForUserId = (channelId: string, userId: string): AppThunk => async (dispatch, getState) => {
  try {
    const sessionBody = new SessionBody({
      userId,
    });
    const { login: { user: { access_token } } } = getState();
    const state = getState()
    const client = new Client(state.config.baseUrl)
    // @ts-ignore
    client.instance.defaults.headers.common.Authorization = access_token;
    const session = await client.session(channelId, sessionBody);
    dispatch(getSessionSuccess(session.toJSON()));
  } catch (err) {
    dispatch(getSessionFailed(err.toString()));
  }
};
