import React, { FunctionComponent } from 'react';
import {
  Button, Box, makeStyles, Paper,
} from '@material-ui/core';
import { withTheme } from '@rjsf/core';
import { Theme as MuiTheme } from '@rjsf/material-ui';

import SaveIcon from '@material-ui/icons/Save';
import { useDispatch } from 'react-redux';
import { animated } from 'react-spring'

import { sentMicrocontextUpdate } from '../../redux/bricks';

import MircoContexSchema from '../../models/MicroContext.json';

interface MicroContextProps {
  brick: any,
}

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
    width: '100%',
    boxSizing: 'border-box'
  }
}))

const AnimatedPaper = animated(Paper)


const Form = withTheme(MuiTheme);

export const MicroContext: FunctionComponent<MicroContextProps> = ({ brick }) => {
  const classes = useStyles()
  const dispatch = useDispatch();

  let formData = {}
  if (brick?.content?.data) {
    formData = brick.content.data;
  }
  return (
    <AnimatedPaper elevation={3} className={classes.root}>

      <Form
        // @ts-ignore
        schema={MircoContexSchema}
        formData={formData}
        onChange={(data) => {
          console.log('onChange data', data);
        }}
        onSubmit={(data) => {
          console.log('onSubmit data', data);
          dispatch(sentMicrocontextUpdate(data.formData));
        }}
        noHtml5Validate
      >

        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
          >
            <SaveIcon />
            Submit
          </Button>


        </Box>
      </Form>

    </AnimatedPaper>
  );
};
