import React, { FunctionComponent } from 'react'
import { FormControl, InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core'

interface SelectLayoutProps {
  layouts: string[]
  onChange(event: React.ChangeEvent<{ name?: string; value: unknown }>): void
  value: string | number
}
const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%'
  }
}))

export const SelectLayout: FunctionComponent<SelectLayoutProps> = ({ layouts, value, onChange }) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">Alternative Layout</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onChange={onChange}
        value={value}
      >
        {layouts.map(layout => (
          <MenuItem key={layout} value={layout}>{layout}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}