import React, { FunctionComponent, useState, useEffect } from 'react'
import { animated, useSpring } from 'react-spring'
import { Brick } from '../../models'
import { Line, Bar, Pie, BarProps } from '../charts'
import { Paper, Button, Box } from '@material-ui/core'
import { Title } from './Title'
import { SelectLayout } from './SelectLayout'
import { Table } from '../Table/Table'
import { useDispatch } from 'react-redux'
import { toggleExpand, setHeight } from '../../redux/masonry'
const AnimatedPaper = animated(Paper)

interface Props {
  brick: Brick<'timelineData'>
}

type State = 'Line' | 'Bar' | 'BarStacked' | 'Pie'

const chartMap = {
  Line: Line,
  Bar: Bar,
  BarStacked: (props: BarProps) => (
    <Bar {...props} stacked />
  ),
  Pie: Pie,
}

const EXPANDED_HEIGHT = 650
const HEIGHT = 450

export const Chart: FunctionComponent<Props> = ({ brick }) => {
  const dispatch = useDispatch()
  const brickData = brick.content?.data
  const [layout, setLayout] = useState<State>(brickData.chart.layouts[0])
  const [showTable, setShowTable] = useState<boolean>(false)
  const { height, tableHeight, opacity } = useSpring({
    height: showTable ? EXPANDED_HEIGHT : HEIGHT,
    tableHeight: showTable ? 200 : 0,
    opacity: showTable ? 1 : 0
  })
  useEffect(() => {
    dispatch(setHeight({ id: brick._id, height: showTable ? EXPANDED_HEIGHT : HEIGHT }))
  })
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => setLayout(event.target.value as State)
  const ChartComponent = chartMap[layout]

  if (!ChartComponent) {
    return (
      <AnimatedPaper elevation={3} style={{ height, padding: 10 }}>
        unknown layout {layout}
      </AnimatedPaper>
    )
  }
  return (
    <AnimatedPaper elevation={3} style={{ height, padding: 10 }}>
      <Title title={brickData.title} />
      {brickData.chart.layouts.length > 0 && (
        <SelectLayout onChange={handleChange} value={layout} layouts={brickData.chart.layouts} />
      )}

      <ChartComponent data={brickData.chartData} dataKeys={brickData.dataKeys} />

      {brickData.table.allowed && (
        <>
          <Box justifyContent="flex-end" flexDirection="row" display="flex">
            <Button
              onClick={() => {
                setShowTable(!showTable)
                dispatch(toggleExpand(brick._id))
              }}>
              {showTable ? 'Tabelle verstecken' : 'Tabelle anzeigen'}
            </Button>
          </Box>
          <Table
            style={{ height: Number(tableHeight), opacity }}
            chartData={brickData.chartData}
            dataKeys={brickData.dataKeys} />
        </>
      )}

    </AnimatedPaper>
  )
}
