import React, { FunctionComponent } from 'react'
import { Box, Typography, useTheme } from '@material-ui/core'

interface DataColumnProps {
  title: string
  bodyTexts: string[]
}



export const DataColumn: FunctionComponent<DataColumnProps> = ({ title, bodyTexts }) => {

  const { spacing } = useTheme()

  return (
    <Box padding={spacing(.1)} minHeight={80}>
      <Typography variant="overline">
        {title}
      </Typography>
      {bodyTexts.map((text, i) => (
        <Typography variant="body1" key={i}>
          {text}
        </Typography>
      ))}
    </Box>
  )
}
