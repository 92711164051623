import React, { FunctionComponent, useEffect } from 'react'
import { DatePickers } from '../DatePickers/DatePickers'
import { useSelector, useDispatch } from 'react-redux'
import { setDateFrom, setDateTo } from '../../redux/bricks'
import { RootState } from '../../redux/rootReducer'
import { CardContent, Card } from '@material-ui/core'

interface DatePickerProps {
  brick: any
}


export const DatePicker: FunctionComponent<DatePickerProps> = ({ brick }) => {
  const { dateFrom, dateTo } = useSelector(({ bricks }: RootState) => bricks)
  const dispatch = useDispatch()

  const dateToContent = brick.content?.data?.dateTo;
  const dateFromContent = brick.content?.data?.dateFrom;

  useEffect(() => {
    if (dateFromContent && !dateFrom) {
      dispatch(setDateFrom(new Date(dateFromContent)))
    }
    if (dateToContent && !dateTo) {
      dispatch(setDateTo(new Date(dateToContent)))
    }
  }, [dateToContent, dateFromContent, brick.content, dispatch, dateFrom, dateTo])

  return (
    <Card elevation={2}>
      <CardContent>
        <DatePickers
          dateFrom={dateFrom}
          dateTo={dateTo}
          onChangeFrom={date => dispatch(setDateFrom(date))}
          onChangeTo={date => dispatch(setDateTo(date))}
        />
      </CardContent>
    </Card>
  )
}
