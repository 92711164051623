import React, { FunctionComponent } from 'react'
import {Button, Grid, makeStyles, Paper} from '@material-ui/core'
import { ISuggestedOptions, IBrick } from "@agilegravity/agile-ai-event-client-ts";

interface SearchSuggestionsProps {
  brick: IBrick,
  suggestOptionClick(suggestedOption: ISuggestedOptions): void
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: '100%',
  },
  item: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

export const SearchSuggestions: FunctionComponent<SearchSuggestionsProps> = ({ brick, suggestOptionClick }) => {
  const classes = useStyles()
  return (
    <Paper className={classes.root}  elevation={3}>
      <Grid container justify="center" alignItems="center" spacing={1}>
        {brick.suggestedOptions?.map((option: ISuggestedOptions) => (
          <Grid item xs={6} key={option._id} className={classes.item}>
            <Button onClick={
              () => suggestOptionClick(option)
            } variant="contained">
              {option.content?.data.text}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}
