import React, { FunctionComponent, useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { sentTextEvent } from '../../redux/bricks';
import { Autocomplete as AutocompleteField } from '../Autocomplete';

interface AutocompleteProps {
  brick: any,

}


export const Autocomplete: FunctionComponent<AutocompleteProps> = ({ brick }) => {
  const dispatch = useDispatch();
  const debounceOnChange = useCallback(debounce((text) => {
    dispatch(sentTextEvent(text));
  }, 500), []);
  let suggestions = [];
  if (brick && brick.suggestedOptions) {
    suggestions = brick.suggestedOptions.map((option) => option.content?.data?.text);
  }
  return (
    <Box>
      <AutocompleteField
        suggestions={suggestions}
        label="Suche"
        onChange={debounceOnChange}
      />

    </Box>
  );
};
