import React, { useEffect, useState, FunctionComponent } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { useSelector } from "react-redux";
import {
  Box,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  useTheme,
} from "@material-ui/core";
import {IJSONSchema, State} from "@agilegravity/agile-ai-trainerserver-client-ts";

import { set, cloneDeep } from 'lodash';
import traverse from 'traverse';


import EventSchemaCore from './EventSchemaCore.json'

import { UniversalBrick } from "../../components";
import { RootState } from "../../redux/rootReducer";
import Grid from "@material-ui/core/Grid";


import { UniversalEvent } from "../../components/Events/UniversalEvent";

export interface EventPlaygroundProps {
  eventPlaygroundJSONSchemas?: IJSONSchema[];
  eventPlaygroundTpKeys?: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      // minWidth: 120,
    },
  })
);
export const EventPlayground: FunctionComponent<EventPlaygroundProps> = ({
  eventPlaygroundJSONSchemas = [],
  eventPlaygroundTpKeys = []
}) => {

  // @ts-ignore
  const filteredEventJSONSchemas = eventPlaygroundJSONSchemas.filter((jsonSchema) => jsonSchema.state.includes(State.Event))
  const classes = useStyles();
  const { bricks } = useSelector(({ bricks }: RootState) => bricks);
  const { spacing } = useTheme();

  const [selectedJsonScheamIndex, setSelectedJsonSchemaIndex] = useState(-1);
  const [jsonSchema, setJsonSchema] = useState(EventSchemaCore);

  return (
    <Grid container spacing={3} style={{ width: "100%" }}>
      <Grid item xs={8}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="80vh"
        >
          {bricks.map((brick) => {
            return (
              <Box marginBottom="20px">
                <Paper elevation={1}>
                  <UniversalBrick key={brick._id} brick={brick} />
                </Paper>
              </Box>
            );
          })}
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box marginTop={spacing(1)} marginBottom={spacing(1)}>
          <Paper elevation={1}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                EventJsonSchema
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedJsonScheamIndex}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {

                  if (event.target.value as number === -1) {
                    setJsonSchema(cloneDeep(EventSchemaCore));
                  }

                  if(filteredEventJSONSchemas && event.target.value as number > -1){
                    const schema = cloneDeep(EventSchemaCore);
                    schema.properties.content = {
                      properties: {
                        data: cloneDeep(filteredEventJSONSchemas[event.target.value as number].jsonSchema),
                        schemaRef: {
                          type: "string",
                          enum: [filteredEventJSONSchemas[event.target.value as number].name],
                          default: filteredEventJSONSchemas[event.target.value as number].name,
                        }
                      }
                    };

                    const tpKeyPathList = traverse(schema)
                      // get all paths as array [[path1], [path2]]
                      .paths()
                      .filter((array: any) => array[array.length - 1] === 'tpKey')
                      .map((array: any) => array.join('.'));
                    console.log('tpKeyPathList', tpKeyPathList);
                    tpKeyPathList.forEach((tpKeyPath: string) => {
                      set(schema, `${tpKeyPath}.enum`, eventPlaygroundTpKeys);
                      set(schema, `${tpKeyPath}.default`, eventPlaygroundTpKeys[0]);
                    });
                    setJsonSchema(schema);

                  }

                  setSelectedJsonSchemaIndex(event.target.value as number);
                }}
              >
                <MenuItem value={-1}>
                  <em>None</em>
                </MenuItem>
                {filteredEventJSONSchemas.map((jsonSchema, i) => (
                  <MenuItem key={i} value={i}>{jsonSchema.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>
        </Box>
          <UniversalEvent
            key={selectedJsonScheamIndex}
            jsonSchema={jsonSchema}
            // eventTemplate={}
            // onChange={(formData) => setEventTemplate(formData)}
          />
        <Box>
          <pre>
            {JSON.stringify(jsonSchema, null, 2)}
          </pre>
        </Box>
      </Grid>
    </Grid>
  );
};
