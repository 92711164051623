import React from 'react'
import { useTransition, animated } from 'react-spring'
import { useMeasure } from './useMeasure'
import useMedia from './useMedia'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/rootReducer'

const PADDING = 40
export const DEFAULT_HEIGHT = 400

const useStyles = makeStyles({
  list: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  wrapper: {
    position: 'absolute',
    'will-change': 'transform, width, height, opacity',
  },

  item: {
    padding: '10px',
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '8px',
    boxShadow: '0px 10px 50px - 10px rgba(0, 0, 0, 0.2)',
  }

})

const defaultTransition = (gridItems: any): [any, any, any] => [
  gridItems, item => item.id, {
    from: ({ xy, width, height }) => ({ xy, width, height, opacity: 0 }),
    enter: ({ xy, width, height }) => ({ xy, width, height, opacity: 1 }),
    update: ({ xy, width, height }) => ({ xy, width, height }),
    leave: { height: 0, opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
    trail: 25
  }
]

const expandedItemTransition = (_expandedItemId, gridItems): [any, any, any] => {
  return [
    gridItems, item => item.id, {
      from: ({ xy, width, height, ...rest }) => ({ xy, width, height, opacity: 0 }),
      enter: ({ xy, width, height }) => ({ xy, width, height, opacity: 1 }),
      update: ({ xy, width, height }) => ({ xy, width, height }),
      leave: { height: 0, opacity: 0 },
      config: { mass: 5, tension: 500, friction: 100 },
      trail: 25
    }
  ]
}

const getGridItemsAndHeight = (childrenWithHeights, expanedItemId, width, columns): Array<any> => {
  let rowHeight = 0
  let columnIndex = 0
  let gridItems = []
  if (expanedItemId) {
    const expandedItem = childrenWithHeights.find(({ id }) => id === expanedItemId)
    // @ts-ignore
    gridItems = [{ ...expandedItem, xy: [0, 0], width, height: expandedItem.height }]
    rowHeight = expandedItem.height
  } else {
    gridItems = childrenWithHeights.map((item, i) => {
      const column = columnIndex
      const xy = [(width / columns) * column, rowHeight]
      if (columnIndex === columns - 1 || i === childrenWithHeights.length - 1) {
        // rowHeight+= childrenWithHeights.slice(i - columns, i)
        rowHeight += Math.max(...childrenWithHeights.map(({ height }) => height))
        columnIndex = 0
      } else {
        columnIndex++
      }
      return { ...item, xy, width: width / columns, height: item.height }
    })
  }
  return [gridItems, rowHeight]
}

export interface MasonryItem {
  id: string
}

export const Masonry = ({ children }) => {
  const { heights, expandedItemId } = useSelector((state: RootState) => state.masonry)
  const childrenWithHeights = children.map((child) => {
    const height = heights[child.props.id] || 0
    return { child, id: child.props.id, height: height ? height + PADDING : DEFAULT_HEIGHT + PADDING }
  })
  const classes = useStyles()

  // @ts-ignore
  const [bind, { width }] = useMeasure()
  const columns = useMedia(['(min-width: 1500px)', '(min-width: 1000px)', '(min-width: 600px)', '(min-width: 480px)'], [4, 3, 2, 1], 1)


  let [gridItems, rowHeight] = getGridItemsAndHeight(childrenWithHeights, expandedItemId, width, columns)
  // .filter(({ id }) => expandedItemId !== undefined ? id === expandedItemId : true)
  // Hook5: Turn the static grid values into animated transitions, any addition, removal or change will be animated
  const transitions = useTransition(...(expandedItemId ? expandedItemTransition(expandedItemId, gridItems) : defaultTransition(gridItems)))

  // Render the grid
  return (
    // @ts-ignore
    <div ref={bind} className={classes.list} style={{ height: rowHeight }}>
      {
        // @ts-ignore
        transitions.map(({ item, props: { xy, ...rest }, key }) => {
          return (
            <animated.div className={classes.wrapper} key={key} style={{ transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`), ...rest }}>
              <div className={classes.item}>
                {item.child}
              </div>
            </animated.div>
          )
        }
        )
      }
    </div>
  )
}

