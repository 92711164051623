import React, { FunctionComponent, useEffect } from 'react'
import { Brick } from '../../models'
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core'
import accounting from 'accounting'
import { useDispatch } from 'react-redux'
import { setHeight } from '../../redux/masonry'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

interface Props {
  brick: Brick<'kpiData'>
}


export const Kpi: FunctionComponent<Props> = ({ brick }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setHeight({ id: brick._id, height: 300 }))
  }, [brick, dispatch])
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {brick.content.data.title}
        </Typography>
        <Typography variant="h5" component="h2">
          {accounting.formatNumber(brick.content.data.kpiData.value, 2, ".", ",")}
        </Typography>
        <Typography color="textSecondary">
          Vormonat: {accounting.formatNumber(brick.content.data.kpiData.refrence, 2, ".", ",")}
        </Typography>
      </CardContent>
    </Card>
  )
}
