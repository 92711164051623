import React from 'react';
import Tree from 'react-d3-tree';
import {Card, makeStyles,} from "@material-ui/core";



const useStyles = makeStyles(() => ({
  root: {
    height: 1800,

  }
}))
export function D3Tree({brick}) {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
     <Tree
       translate={{x: 50, y: 400}}
       data={brick.content.data}
     />
    </Card>
  );
}
