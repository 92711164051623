// export const BASE_URL = 'https://agile-demo-ai-event-api.agilegravity.com';
import {createSlice} from "@reduxjs/toolkit";

// export const BASE_URL = "http://localhost:3017";

//export const BASE_URL = "https://green-develop-event-api-agile-ai.nexcheck.de";
// export const BASE_URL =
//   "https://green-production-event-api-agile-ai.nexcheck.de";

//export const CHANNEL_ID = "5ebe63b025dc9a110c086cf1";
interface ConfigState {
  baseUrl: string,
}

const initialState: ConfigState = { baseUrl: "http://localhost:3017" }

const loginSlice = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    setBaseUrl: (state, action) => ({
      ...state,
      baseUrl: action.payload,
    }),
  }
})
export const { setBaseUrl } = loginSlice.actions

export default loginSlice.reducer
