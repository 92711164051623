import React, {FunctionComponent} from 'react'
import {Button, Grid, Paper, Typography} from '@material-ui/core'
import {IBrick, ISuggestedOptions2} from "@agilegravity/agile-ai-event-client-ts";
import {makeStyles} from "@material-ui/core/styles";

interface GenericTextConversionProps {
  brick: IBrick,
  suggestOptionClick(suggestedOption: ISuggestedOptions2): void
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: '100%',
  }
}))


export const GenericTextConversion: FunctionComponent<GenericTextConversionProps> = ({brick, suggestOptionClick}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={3}>
      <Typography>{brick.content.data.title}</Typography>
      <Grid container justify="center">
      {brick.suggestedOptions?.map((option: ISuggestedOptions2) => (
        <Grid item xs={12} key={option._id} >
          <Button onClick={
            () => suggestOptionClick(option)
          } variant="outlined">
            {option.name}
          </Button>
        </Grid>
      ))}
      </Grid>
    </Paper>

  )
}
