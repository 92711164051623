import React, { FunctionComponent, useState, KeyboardEvent, ChangeEvent } from 'react'
import { TextField, makeStyles } from '@material-ui/core'
import matchSorter from 'match-sorter'

const useStyle = makeStyles(({ typography }) => ({
  root: {
    position: 'relative',
  },
  textInput: {
    width: '100%',
  },
  typeahead: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    ...typography.caption,
  },
  typeaheadInput: {
    color: 'grey',
    width: '100%',
  }
}))

interface AutocompleteProps {
  suggestions: string[]
  label?: string
  onChange?(value: string): void
}


export const Autocomplete: FunctionComponent<AutocompleteProps> = ({ suggestions, label = '', onChange: onChangeListener }) => {
  const [suggestion, setSuggestion] = useState<string>('')
  const [textfieldValue, setTextfieldValue] = useState<string>('')
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChangeListener) {
      onChangeListener(event.target.value || '')
    }
    createSuggestion(event.target.value)
    setTextfieldValue(event.target.value)
  }

  const createSuggestion = (value: string) => {
    if (value === '') {
      setSuggestion('')
      return
    }
    const foundSuggestions = matchSorter(suggestions, value)

    if (foundSuggestions.length === 0) {
      // fill up with spaces so that suggestion textfield scrolls
      setSuggestion(' '.repeat(value.length))
      return
    }
    const newSuggestion = foundSuggestions[0]
    const concatinatedSuggestion = value + newSuggestion.substr(value.length, newSuggestion.length)
    setSuggestion(concatinatedSuggestion)
  }


  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab' && suggestion !== '') {
      event.preventDefault()
      setTextfieldValue(suggestion)
    }
  }

  const classes = useStyle()
  return (
    <div className={classes.root}>
      <TextField
        label={label === '' ? '' : '  '}
        className={classes.typeahead}
        value={suggestion}
        inputProps={{ className: classes.typeaheadInput }} />
      <TextField
        label={label}
        className={classes.textInput}
        value={textfieldValue}
        onChange={onChange}
        onKeyDown={onKeyDown} />
    </div>
  )
}
