import React, {useCallback, FunctionComponent, useState, useEffect} from "react";
import { makeStyles, Paper, Fab } from "@material-ui/core";
import { withTheme } from "@rjsf/core";
import { Theme as MuiTheme } from "@rjsf/material-ui";

import SaveIcon from "@material-ui/icons/Save";
import { useDispatch } from "react-redux";
import { animated } from "react-spring";
import { debounce } from 'lodash';

import { sentUniversalEvent } from "../../redux/bricks";


interface UniversalEventProps {
  eventTemplate?: any;
  onChange?: any;
  jsonSchema: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    width: "100%",
    boxSizing: "border-box",
    position: "relative",

  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
}));

const AnimatedPaper = animated(Paper);

const Form = withTheme(MuiTheme);

export const UniversalEvent: FunctionComponent<UniversalEventProps> = ({
  eventTemplate,
  onChange,
  jsonSchema,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(eventTemplate)
  useEffect(() => {
    setFormData(eventTemplate)
  }, [eventTemplate])

  const debounseSentUniversalEvent = useCallback(debounce((formData => {
    try {
      dispatch(sentUniversalEvent(formData));
    } catch (e) {
      console.log('sentUniversalEvent error', e)
    }
  }), 250), []);


  const uiSchema = {
    content:{
      data:{
        integerRange: {
          "ui:widget": "range"
        }
      }
    }
  }
  // @ts-ignore
  return (
    <AnimatedPaper elevation={3} className={classes.root}>
      <Form
        // @ts-ignore
        schema={jsonSchema}
        uiSchema={uiSchema}
        formData={formData}
        onChange={(data) => {
          console.log("onChange data", data);
          if(onChange){
            onChange(data.formData);
          }
          setFormData(data.formData);
          debounseSentUniversalEvent(data.formData)
        }}
        onSubmit={(data) => {
          console.log("onSubmit data", data);
          debounseSentUniversalEvent(data.formData)
        }}
        noHtml5Validate
      >
        <Fab
          className={classes.fab}
          variant="extended"
          color="primary" type="submit"
        >
          <SaveIcon />
          Submit
        </Fab>

      </Form>

      <pre>
        {JSON.stringify(eventTemplate, null, 2)}
      </pre>
    </AnimatedPaper>
  );
};
