import React, { FunctionComponent } from 'react'
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar as RechartsBar,
  ResponsiveContainer
} from 'recharts'
import { DataKey } from '../../models';

export interface BarProps {
  data: object[]
  stacked?: boolean
  dataKeys: Array<DataKey>
}

const DEFAULT_COLOR = '#8884d8'

export const Bar: FunctionComponent<BarProps> = ({ data, stacked = false, dataKeys }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis width={30} />
        <Tooltip />
        <Legend />
        {dataKeys.map(({ key, color }) => (
          <RechartsBar key={key} dataKey={key} fill={color ? color : DEFAULT_COLOR} stackId={stacked ? 'a' : undefined} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}