import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import { SuggestedOptions } from "@agilegravity/agile-ai-event-client-ts";

import { Brick, SchemaRef } from '../../models';
import {
  Kpi,
  Chart,
  Unknown,
  GenericTextConversion,
  SearchResult,
  SearchSuggestions,
  Autocomplete,
  MicrointendList,
  ContractData,
  CustomerData,
  DatePicker,
  MicroContext,
  D3Tree,
} from '../Bricks';

import { sentSuggesteOptionEvent } from '../../redux/bricks'
import { useDispatch } from "react-redux";

const brickMap = new Map<string, any>([
  ['timelineData', Chart],
  ['datePicker', DatePicker],
  ['quantitativeData', Chart],
  ['kpiData', Kpi],
  ['genericTextConversation', GenericTextConversion],
  ['searchResult', SearchResult],
  ['searchResultExtended', SearchResult],
  ['searchSuggestion', SearchSuggestions],
  ['searchTermAutocompletion', Autocomplete],
  ['microintendList', MicrointendList],
  ['contractData', ContractData],
  ['customerData', CustomerData],
  ['MicroContext', MicroContext],
  ['3DTree', D3Tree],
]);


const useStyles = makeStyles((theme) => ({
  root: {
    // marginBottom: theme.spacing(2)
  },
}));

interface Props {
  brick: Brick<SchemaRef>
}
export const UniversalBrick: FunctionComponent<Props> = ({ brick }) => {
  const dispatch = useDispatch()
  const classes = useStyles();
  if(!brick){
    return null;
  }
  const Component = brickMap.get(brick.content.schemaRef);
  if (!Component) {
    return <Unknown brick={brick} />;
  }
  return (
    <div className={classes.root}>
      <Component
        brick={brick}
        suggestOptionClick={(suggestedOption: SuggestedOptions) => {
          dispatch(sentSuggesteOptionEvent(brick, suggestedOption));
        }}
      />
    </div>
  );
};
