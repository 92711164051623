import React, { FunctionComponent } from 'react'
import { Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core'
import { DataColumn } from '../DataColumn'
import accounting from 'accounting'
import PowerIcon from '@material-ui/icons/PowerOutlined'


const useStyles = makeStyles(() => ({
  gridItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  powerIcon: { verticalAlign: 'middle' }
}))


interface ContractDataProps {
  brick: any
}


export const ContractData: FunctionComponent<ContractDataProps> = ({ brick }) => {
  const classes = useStyles()
  const {
    deliverySource,
    meterNumber,
    tariff,
    deliveryStartDate,
    accountData,
    rate,
    contractNumber
  } = brick.content.data
  return (
    <Card elevation={2}>
      <CardContent>
        <Typography variant="h5">
          <PowerIcon fontSize="large" className={classes.powerIcon} /> Strom {contractNumber || ''}
        </Typography>
        <Grid container className={classes.row}>
          <Grid item xs={12} md={4}>
            <DataColumn title="Lieferstelle" bodyTexts={[deliverySource]} />
            <DataColumn title="Zählernummer" bodyTexts={[meterNumber]} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DataColumn title="Tarif" bodyTexts={[tariff]} />
            <DataColumn title="Lieferbeginn" bodyTexts={[deliveryStartDate]} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DataColumn title="Bankdaten" bodyTexts={[accountData]} />
            <DataColumn title="Abschlag" bodyTexts={[accounting.formatMoney(rate, "€", 2, ".", ",", '%v %s')]} />
          </Grid>
        </Grid>
      </CardContent>
    </Card >
  )
}
